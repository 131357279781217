import moment from "moment/moment";
import {pageSize} from "@/utils/common";

// 页面初始状态
export const formitemList = [
    {
        labelName: "模板名称",
        key: 'templateName',
        type: "input",
        placeholder: "请输入模板名称查询",
    },
    {
        labelName: "模板ID",
        key: 'templateNo',
        type: "input",
        placeholder: "请输入模板ID查询",
    },
    // {
    //     labelName: "创建时间",
    //     key: 'createTime',
    //     type: "daterange",
    // },
    {
        labelName: "启用状态",
        key: 'isEnable',
        type: "select",
        placeholder: "请选择启用状态",
        lableWidth: '78px',
        option: [
            {
                label: '全部',
                value: null
            },
            {
                label: '启用',
                value: true
            },
            {
                label: '禁用',
                value: false
            }
        ]
    },
];
export const initData = {
    isEnable: null,
    // createTime: [
    //     moment().subtract(6, 'day').startOf('day').format("YYYY-MM-DD"),
    //     moment().endOf('day').format("YYYY-MM-DD")
    // ]
}

export const param = {
    // 当前页数
    pageNum: 1,
    // 当前条数
    pageSize,
    // createTime: [
    //     moment().subtract(6, 'day').startOf('day').format("YYYY-MM-DD"),
    //     moment().endOf('day').format("YYYY-MM-DD")
    // ],
}

export const tableColumns = [
    {
        prop: "index",
        label: "序号",
        width: "60px"
    },
    {
        prop: "templateNo",
        label: "模板ID",
    },
    {
        prop: "typeName",
        label: "分类",
    },
    {
        prop: "isIos",
        label: "品牌",
    },
    {
        prop: "templateName",
        label: "模板名称",
    },
    {
        prop: "updateUser",
        label: "更新人",
    },
    {
        prop: "updateTime",
        label: "最近更新时间",
    },
    {
        prop: "isEnable",
        label: "启用/禁用",
    },
    {
        prop: "action",
        label: "操作",
    },
];