<template>
  <div class="box">
    <!-- 头部 -->
    <GlobalInfoBar
        title="旧机模板管理"
        content="编辑旧机商品模板"
    />
    <!-- 查询 -->
    <GlobalChunk icon="search"
                 title="筛选查询">
      <!-- 筛选 -->
      <template v-slot:filter>
        <GlobalForm
            :form-item-list="formitemList"
            round
            size="mini"
            :inline="true"
            :needBtnLoading="true"
            @handleConfirm="handleConfirm"
            :init-data="initData"
            confirm-btn-name="查询"
        >
        </GlobalForm>
      </template>
      <template v-slot:default>
        <el-table
            border
            size="small"
            :data="tableData"
            v-loading="loading"
            :header-cell-style="{background:'#E4ECFD',padding:0}"
        >
          <el-table-column v-for="item in tableColumns"
                           :key="item.prop"
                           :prop="item.prop"
                           align="center"
                           :label="item.label"
                           :width="item.width"
          >
            <template v-slot="{row}">
              <div v-if="item.prop === 'action'" style="color: rgb(35,132,252);cursor: pointer"
                   @click="$router.push(`/oldMachine/templateEdit?id=${row.templateNo}&typeName=${row.typeName}&isIos=${row.isIos}`)">
                编辑模板
              </div>
              <div v-else-if="item.prop === 'isEnable'" class="enable_box">
                <el-switch
                    active-text="开"
                    inactive-text="关"
                    inactive-color="#b6b9d0"
                    @change="(v)=>switchChange(v,row.templateNo)"
                    v-model="row[item.prop]">
                </el-switch>
              </div>
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :meta="meta"
                    @sizeChange="(v)=>{meta = {...meta,pageSize: v};param = {...param,pageSize:v}}"
                    @pageChange="(v)=>{meta = {...meta,pageNum:v};param = {...param,pageNum:v}}"
        />
      </template>
    </GlobalChunk>
  </div>
</template>
<script>
import GlobalInfoBar from "@/components/global/components/GlobalInfoBar.vue";
import GlobalChunk from "@/components/global/components/GlobalChunk.vue";
import GlobalForm from "@/components/global/components/GlobalForm.vue";
import {formitemList, initData, param, tableColumns} from "@/views/oldMachine/templateList/initState";
import {getHaoTime, getIndex, getMeta, getTemplateCategory, infoConcat, pageSize, setValue} from "@/utils/common";
import _api from "@/utils/request";
import Pagination from "@/components/common/Pagination.vue";

export default {
  components: {Pagination, GlobalForm, GlobalChunk, GlobalInfoBar},
  data() {
    return {
      // 搜索参数
      formitemList,
      // 搜索参数初始化
      initData,
      // 搜索参数
      param,
      // 表列
      tableColumns,
      // 表
      tableData: [],
      // 表加载
      loading: true,

      // 分页
      meta: {
        total: 0,
        pageSize,
        pageNum: 1,
      },
    }
  },
  created() {
    this.getData();
  },
  watch: {
    param() {
      this.getData();
    }
  },
  methods: {
    // 请求
    getData() {
      this.loading = true;
      const param = this.param;
      // const params = {
      //   ...this.param,
      //   createStartTime: param.createTime ? getHaoTime(param.createTime[0]) : null,
      //   createEndTime: param.createTime ? getHaoTime(param.createTime[1]) : null
      // };
      _api.productTemplateList(param).then(r => {
        const data = r.data.records ?? [];
        this.loading = false;
        this.tableData = data.map((v, i) => {
          // 拼接信息
          v.updateUser = infoConcat(v.updateUsername, v.updateAccount);
          // 设置--
          v = setValue(v);
          return {
            ...v,
            index: getIndex(param.pageNum, param.pageSize, i),
            isIos: v.isIos === '--' ? '--' : v.isIos === '01' ? '苹果' : '非苹果',
            isEnable: v.isEnable === '--' ? false : v.isEnable,
            // typeCode: getTemplateCategory(v.typeCode),
          }
        });
        // 分页信息获取
        this.meta = getMeta(r.data.total, r.data.current, r.data.size);
      }).finally(() => {
        if (typeof this.param.disuseLoding === 'function') {
          this.param.disuseLoding();
        }
      });
    },
    switchChange(isEnable, templateNo) {
      _api.productTemplateSetEnable({isEnable, templateNo}).then(() => {
        this.getData();
        this.$message.success(isEnable ? "已启用" : '已禁用');
      });
    },
    // 查询事件
    handleConfirm(v,cd) {
      this.param = {
        ...v,
        ...this.meta,
        pageNum: 1,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.enable_box {
  /deep/ .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  /deep/ .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  /deep/ .el-switch__label--right {
    z-index: 1;
    right: 4px;
  }

  /*关闭时文字位置设置*/
  /deep/ .el-switch__label--left {
    z-index: 1;
    left: 4px;
  }

  /*显示文字*/
  /deep/ .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  /deep/ .el-switch .el-switch__core,
  /deep/ .el-switch .el-switch__label {
    width: 50px !important;
  }
}
</style>
